import HMOApi, { Laboratory, MyDefaultLabUnits } from "store/api/HMOApi";
import utils from "../../../utils/Utils";

const BASE_URL = "/laboratory";

export const LaboratoryApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchLaboratoryByPatient: build.query({
            query: ({ patientId }) => ({ url: `${BASE_URL}/patient/${patientId}` }),
            providesTags: [Laboratory],
            transformResponse: response => (response
                ?.map(item=> ({...item, ids: Object.values(item?.ids||{})}))
                ?.sort((a, b) => new Date(a.recordDate) - new Date(b.recordDate))),
        }),

        saveLaboratory: build.mutation({
            query: (data) => ({
                url: BASE_URL,
                method: "PUT",
                data: {...data, recordDate: data.recordDate?.format(utils.API_LOCAL_DATE_FORMAT)}
            }),
            invalidatesTags: [Laboratory, MyDefaultLabUnits],
        }),

        updateLaboratoryValue: build.mutation({
            query: ({ payload }) => ({
                url: BASE_URL,
                method: "POST",
                data: {...payload, recordDate: payload.recordDate?.format(utils.API_LOCAL_DATE_FORMAT)},
            }),
            invalidatesTags: [Laboratory, MyDefaultLabUnits],
        }),

        deleteLaboratory: build.mutation({
            query: ({ ids }) => ({
                url: BASE_URL,
                method: "DELETE",
                data: {ids}
            }),
            invalidatesTags: [Laboratory],
        }),
        myDefaultLabUnits: build.query({
            query: () => ({
                url: `/default-lab-units/me`,
            }),
            providesTags: [MyDefaultLabUnits],
        }),
        setMyDefaultLabUnits: build.mutation({
            query: ({ payload }) => ({
                url: `/default-lab-units/me`,
                method: "POST",
                data: payload,
            }),
            invalidatesTags: [MyDefaultLabUnits],
        }),
    }),
});

export const {
    useFetchLaboratoryByPatientQuery,
    useSaveLaboratoryMutation,
    useUpdateLaboratoryValueMutation,
    useDeleteLaboratoryMutation,
    useMyDefaultLabUnitsQuery,
    useSetMyDefaultLabUnitsMutation,
} = LaboratoryApi;
