import { ClickAwayListener, MenuItem, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
    useAddPatientsToCohortMutation,
    useListCohortsQuery,
    useRemovePatientsFromCohortMutation,
} from "../cohort-filters/CohortApi";
import _ from "lodash";
import { useFetchLettersForCareProviderQuery } from "../patient/LetterApi";
import { useSelector } from "react-redux";
import { isDoctor } from "../../domain/User.model";

const PatientRowContextMenu = (props) => {
    const { t: translate } = useTranslation();
    const { x, y, resetPosition, toggleNewProgramDialog, checkedPatientIds, selectedCohortId, printLetters } = props;
    const user = useSelector((state) => state.mainReducer.user);
    const careProviderId = user?.careProviderIds?.[0];
    const [showCohorts, setShowCohorts] = useState(false);
    const [showPrintLetters, setShowPrintLetters] = useState(false);
    const showCohortsRef = useRef(null);
    const showLetterRef = useRef(null);
    const onAddToCohortMouseEnter = (event) => {
        setShowCohorts(true);
    };

    const onAddToCohortMouseLeave = (event) => {
        setShowCohorts(false);
    };
    const onPrintLettersMouseEnter = (event) => {
        setShowPrintLetters(true);
    };

    const onPrintLettersMouseLeave = (event) => {
        setShowPrintLetters(false);
    };

    const [addPatientsToCohort] = useAddPatientsToCohortMutation();
    const [removePatientsFromCohort] = useRemovePatientsFromCohortMutation();
    const { data: cohorts = [] } = useListCohortsQuery();
    const { data: letters = [] } = useFetchLettersForCareProviderQuery(careProviderId, {skip: !isDoctor(user)});

    const addToCohort = (cohortId) => {
        if (cohortId && checkedPatientIds.length) {
            addPatientsToCohort({ cohortId, patientIds: checkedPatientIds });
            resetPosition();
        }
    };

    const removeFromCohort = () => {
        if (selectedCohortId && checkedPatientIds.length) {
            removePatientsFromCohort({ cohortId: selectedCohortId, patientIds: checkedPatientIds });
            resetPosition();
        }
    };

    return (
        <ClickAwayListener onClickAway={resetPosition}>
            <Tooltip title={_.isEmpty(checkedPatientIds) ? translate('cohort.please-select-patients') : ''}>
            <div
                style={{
                    position: "absolute",
                    backgroundColor: "white",
                    border: "1px solid lightgrey",
                    borderRadius: 4,
                    left: x,
                    top: y,
                }}
            >
                <MenuItem disabled={_.isEmpty(checkedPatientIds)} onClick={toggleNewProgramDialog}>
                    {translate("global.add-new-program")}
                </MenuItem>
                <MenuItem
                    disabled={_.isEmpty(checkedPatientIds)}
                    style={{ justifyContent: "space-between", overflow: "unset" }}
                    {...{
                        onMouseEnter: onAddToCohortMouseEnter,
                        onMouseLeave: onAddToCohortMouseLeave,
                    }}
                    ref={showCohortsRef}
                >
                    {translate("cohort.context-menu-add-to-cohort")}
                    <ArrowForwardIosIcon style={{ color: "grey" }} />
                    {showCohorts && (
                        <div
                            style={{
                                position: "absolute",
                                backgroundColor: "white",
                                border: "1px solid lightgrey",
                                borderRadius: 4,
                                top: -1,
                                left: showCohortsRef?.current?.getBoundingClientRect()?.width,
                            }}
                        >
                            {cohorts.map((cohort) => (
                                <MenuItem key={cohort.id} onClick={() => addToCohort(cohort.id)}>{cohort.name}</MenuItem>
                            ))}
                        </div>
                    )}
                </MenuItem>

                {!!selectedCohortId && (
                    <MenuItem disabled={_.isEmpty(checkedPatientIds)} onClick={removeFromCohort}>
                        {translate("cohort.context-menu-remove-from-cohort")}
                    </MenuItem>
                )}

                {
                        (isDoctor(user) && letters && letters.length > 0) &&
                        <MenuItem
                                disabled={_.isEmpty(checkedPatientIds)}
                                style={{ justifyContent: "space-between", overflow: "unset" }}
                                {...{
                                    onMouseEnter: onPrintLettersMouseEnter,
                                    onMouseLeave: onPrintLettersMouseLeave,
                                }}
                                ref={showLetterRef}
                        >
                            {translate("letter.print-letters-from-template")}
                            <ArrowForwardIosIcon style={{ color: "grey" }} />
                            {showPrintLetters && (
                                    <div
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "white",
                                                border: "1px solid lightgrey",
                                                borderRadius: 4,
                                                top: -1,
                                                left: showLetterRef?.current?.getBoundingClientRect()?.width,
                                            }}
                                    >
                                        {letters.map((letter) => (
                                                <MenuItem key={letter.id} onClick={() => printLetters(letter.id, letter.fileName)}>{letter.fileName}</MenuItem>
                                        ))}
                                    </div>
                            )}
                        </MenuItem>
                }

            </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default PatientRowContextMenu;
