import {
    HEMOGLOBIN,
    KREATININ,
    TOTAL_CHOLESTEROL,
    LDL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    CRP,
    LEUKOZYTEN,
    MCV,
    RDW,
    THROMBOZYTEN,
    GFR,
} from "domain/Laboratory.model";
import { convertByFactors } from "../../../cohort-filters/convertByFactors";

const laboratoryConversionFactors = {
    kreatinin: {
        mgperdl: 1,
        nmolperlml: 88.42,
        mikromolperll: 88.42,
    },
    hemoglobin: {
        gperdl: 1,
        mmolperl: 0.6206,
    },
    crp: {
        mgperl: 1,
        mgperdl: 0.1,
        nmolperll: 9.52,
        nmolperml: 9520,
    },
    cholesterol: {
        mgperdl: 1,
        mmolperl: 0.0259,
        mmolperldl: 0.259,
    },
};


export const LaboratoryGroupList = (lang) => [
    { id: HEMOGLOBIN, title: lang(HEMOGLOBIN), className: { [HEMOGLOBIN]: true }, width: 200 },
    { id: KREATININ, title: lang(KREATININ), className: { [KREATININ]: true } },
    { id: TOTAL_CHOLESTEROL, title: lang(TOTAL_CHOLESTEROL), className: { [TOTAL_CHOLESTEROL]: true } },
    { id: LDL_CHOLESTEROL, title: lang(LDL_CHOLESTEROL), className: { [LDL_CHOLESTEROL]: true } },
    { id: HDL_CHOLESTEROL, title: lang(HDL_CHOLESTEROL), className: { [HDL_CHOLESTEROL]: true } },
    { id: CRP, title: lang(CRP), className: { [CRP]: true }, width: 300 },
    { id: LEUKOZYTEN, title: lang(LEUKOZYTEN), className: { [LEUKOZYTEN]: true } },
    { id: MCV, title: lang(MCV), className: { [MCV]: true } },
    { id: RDW, title: lang(RDW), className: { [RDW]: true } },
    { id: THROMBOZYTEN, title: lang(THROMBOZYTEN), className: { [THROMBOZYTEN]: true } },
    { id: GFR, title: lang(GFR), className: { [GFR]: true } },
];


export const LaboratoryFormSetup = [
    {
        name: HEMOGLOBIN,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "gperdl",
            units: [
                {
                    value: "gperdl",
                    label: "g/dl",
                },
                {
                    value: "mmolperl",
                    label: "mmol/l",
                },
            ],
        },
    },
    {
        name: KREATININ,
        options: {
            mask: "00,0",
            radix: ",",

            defaultUnit: "mgperdl",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mikromolperll",
                    label: "μmol/l",
                },
                {
                    value: "nmolperlml",
                    label: "nmol/ml",
                },
            ],
        },
    },
    {
        name: TOTAL_CHOLESTEROL,
        options: {
            mask: "0000,0",
            radix: ",",

            defaultUnit: "mgperdl",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mmolperldl",
                    label: "mmol/dl",
                },
            ],
        },
    },
    {
        name: LDL_CHOLESTEROL,
        options: {
            mask: "0000,0",
            radix: ",",

            defaultUnit: "mgperdl",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mmolperldl",
                    label: "mmol/dl",
                },
            ],
        },
    },
    {
        name: HDL_CHOLESTEROL,
        options: {
            mask: "0000,0",
            radix: ",",

            defaultUnit: "mgperdl",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mmolperldl",
                    label: "mmol/dl",
                },
            ],
        },
    },
    {
        name: CRP,
        options: {
            mask: "000,0",
            radix: ",",

            defaultUnit: "mgperl",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mgperl",
                    label: "mg/l",
                },
                {
                    value: "nmolperll",
                    label: "nmol/l",
                },
                {
                    value: "nmolperml",
                    label: "nmol/ml",
                },
            ],
        },
    },
    {
        name: LEUKOZYTEN,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "gperl",
            units: [
                {
                    value: "gperl",
                    label: "G/l",
                },
            ],
        },
    },
    {
        name: MCV,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "fperl",
            units: [
                {
                    value: "fperl",
                    label: "f/l",
                },
            ],
        },
    },
    {
        name: RDW,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "percent",
            units: [
                {
                    value: "percent",
                    label: "%",
                },
            ],
        },
    },
    {
        name: THROMBOZYTEN,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "gperl",
            units: [
                {
                    value: "gperl",
                    label: "G/l",
                },
            ],
        },
    },
    {
        name: GFR,
        options: {
            mask: "00,0",
            radix: ",",
            defaultUnit: "mlpermin",
            units: [
                {
                    value: "mlpermin",
                    label: "ml/min",
                },
            ],
        },
    },
];

export const substanceBaseUnits = LaboratoryFormSetup.reduce((acc, laboratoryFormDefault) => ({
    ...acc,
    [laboratoryFormDefault.name] : laboratoryFormDefault.options.defaultUnit
}), {});


/**
 *
 * if default unit is not provided it will return the base unit of the substance
 */
export const substanceAndUnitToUnitLabel = (substance, unit) => {
    const substanceData = substance
        ? LaboratoryFormSetup.find(laboratoryFormDefault => laboratoryFormDefault.name === substance)
        : null;
    return substanceData
        ? unit
            ? substanceData.options.units.find(unitOption => unitOption.value === unit).label
            : substanceData.options.units.find(unitOption => unitOption.value === substanceBaseUnits[substance]).label
        : "";
};


export const ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    width: 800,
    showTooltips: true,
    spanGaps: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            type: "time",
            time: {
                unit: "month",
            },
            ticks: {
                source: "auto",
                autoSkip: true,
            },
        },
        y: {
            display: true,
            suggestedMax: 10,
            suggestedMin: 0,
            title: {
                display: true,
                text: " ",
            },
        },
    },
};

export const ChartData = ({ lang }) => [
    { index: 0, title: lang(HEMOGLOBIN), name: HEMOGLOBIN },
    { index: 1, title: lang(KREATININ), name: KREATININ },
    { index: 2, title: lang(TOTAL_CHOLESTEROL), name: TOTAL_CHOLESTEROL },
    { index: 3, title: lang(LDL_CHOLESTEROL), name: LDL_CHOLESTEROL },
    { index: 4, title: lang(HDL_CHOLESTEROL), name: HDL_CHOLESTEROL },
    { index: 5, title: lang(CRP), name: CRP },
    { index: 6, title: lang(LEUKOZYTEN), name: LEUKOZYTEN },
    { index: 7, title: lang(MCV), name: MCV },
    { index: 8, title: lang(RDW), name: RDW },
    { index: 9, title: lang(THROMBOZYTEN), name: THROMBOZYTEN },
    { index: 10, title: lang(GFR), name: GFR },
];

const substanceLabelColors = {
    [HEMOGLOBIN]: "red",
    [KREATININ]: "blue",
    [TOTAL_CHOLESTEROL]: "green",
    [LDL_CHOLESTEROL]: "orange",
    [HDL_CHOLESTEROL]: "purple",
    [CRP]: "black",
    [LEUKOZYTEN]: "#3980a0",
    [MCV]: "#5f7204",
    [RDW]: "#2900db",
    [THROMBOZYTEN]: "#a8206b",
    [GFR]: "#7b883b",
};

export const ChartDataset = ({ laboratoryDtoList, lang, userDefaultUnits }) => {
    return [
        HEMOGLOBIN,
        KREATININ,
        TOTAL_CHOLESTEROL,
        LDL_CHOLESTEROL,
        HDL_CHOLESTEROL,
        CRP,
        LEUKOZYTEN,
        MCV,
        RDW,
        THROMBOZYTEN,
        GFR,
    ].map((substance) => {
        return {
            label:
                lang(substance) +
                " " +
                (userDefaultUnits
                    ? substanceAndUnitToUnitLabel(substance, userDefaultUnits[substance.toLowerCase() + "Unit"])
                    : substanceBaseUnits[substance] || ""),
            coordinates: laboratoryDtoList
                .filter((laboratoryDto) => laboratoryDto[substance] !== null)
                .map((laboratoryDto) => {
                    if (substanceBaseUnits[substance]) {
                        const baseUnit = userDefaultUnits
                            ? userDefaultUnits[substance + "Unit"]
                            : substanceBaseUnits[substance];
                        const inBaseUnit = laboratoryConversionFactors[substance]
                            ? convertByFactors(laboratoryConversionFactors[substance])(laboratoryDto[substance], laboratoryDto[substance + "Unit"], baseUnit)
                            : laboratoryDto[substance];
                        return { x: new Date(laboratoryDto.recordDate), y: inBaseUnit };
                    }
                    return { x: new Date(laboratoryDto.recordDate), y: laboratoryDto[substance] };
                }),
            borderColor: substanceLabelColors[substance],
            fill: false,
        };
    });
};
