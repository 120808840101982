import {useDispatch} from "react-redux";

export const useJustFetch = () => {
    const dispatch = useDispatch();
    return (url) => dispatch({
        type: "JustFetched_"+url,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: url
            }
        }
    })
}

export const useFetchAzureFile = () => {
    const dispatch = useDispatch();
    return (url) => dispatch({
        type: "JustFetchedFile_"+url,
        payload: {
            client: 'empty',
            request: {
                withCredentials: false,
                //LM NOTE:
                // Type selection cannot be omitted here! Otherwise the browser (not axios) will fail silently
                // trying to parse the response as JSON, resulting in empty response data.
                // https://github.com/axios/axios/issues/1409#issuecomment-372497948
                responseType: 'blob',
                method: 'get',
                url: url
            }
        }
    })
}
