import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    useFetchLaboratoryByPatientQuery,
    useMyDefaultLabUnitsQuery,
    useSetMyDefaultLabUnitsMutation,
} from "scenes/patient/laboratory/LaboratoryApi";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { ChartData, ChartDataset, ChartOptions, LaboratoryFormSetup, substanceBaseUnits } from "../config";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import update from "immutability-helper";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";
import _ from "lodash";
import LaboratoryFormDialog from "./LaboratoryFormDialog";
import {
    CRP,
    GFR,
    HDL_CHOLESTEROL,
    HEMOGLOBIN,
    KREATININ,
    LDL_CHOLESTEROL,
    LEUKOZYTEN,
    MCV,
    RDW,
    THROMBOZYTEN,
    TOTAL_CHOLESTEROL,
} from "../../../../domain/Laboratory.model";

Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    chart: {
        maxHeight: 320,
        background: "#F8F8FA",
        border: "1px solid #E2E1E4",
        borderRadius: "15px",
        paddingBottom: "80px",
    },

    chartContainer: {
        position: "relative",
    },

    noChartValuesMessage: {
        color: theme.palette.text.disabled,
        fontSize: "1.1em",
        position: "absolute",
        top: "46%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    chartTitle: {
        marginLeft: "15px",
    },
}));

const LaboratoryDashboard = ({ readonly }) => {
    const { data: userDefaultUnits } = useMyDefaultLabUnitsQuery();
    const [setMyDefaultLabUnitsMutation] = useSetMyDefaultLabUnitsMutation();
    const classes = useStyles();
    const { patientId } = useParams();
    const { t } = useTranslation();
    const lang = useCallback((name) => t(`laboratory.timeline.${name}`), [t]);
    const [chartDataSet, setCharDataSet] = useState(
        _.range(1, ChartData({ lang }).length + 1).map(() => ({ coordinates: [] })),
    );

    const dataSetToMinMaxDates = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
            return { minDate: null, maxDate: null };
        }

        const parseDate = (record) => new Date(record.recordDate);
        const dates = data.map(parseDate);

        const minDate = new Date(Math.min(...dates));
        const maxDate = new Date(Math.max(...dates));

        return { minDate, maxDate };
    };

    const getNoDataForChartLabel = useCallback(
        (valueName) => t(`laboratory.timeline.no-values-yet`, { valueName }),
        [t],
    );
    const { data: laboratoryDtoList, isSuccess: laboratoryDtoListLoadedSuccessfully } = useFetchLaboratoryByPatientQuery(
        { patientId },
        { skip: !patientId },
    );

    useEffect(() => {
        if (laboratoryDtoListLoadedSuccessfully && laboratoryDtoList.length > 0) {
            setCharDataSet(ChartDataset({
                lang,
                laboratoryDtoList,
                userDefaultUnits,
            }));
        }
    }, [lang, laboratoryDtoList, laboratoryDtoListLoadedSuccessfully, userDefaultUnits]);

    const getMinMaxDates = (laboratoryDtoList) => {
        const minMaxDates = laboratoryDtoListLoadedSuccessfully
            ? dataSetToMinMaxDates(laboratoryDtoList)
            : {
                  minDate: null,
                  maxDate: null,
              };
        const minDate = minMaxDates.minDate
            ? new Date(minMaxDates.minDate.setMonth(minMaxDates.minDate.getMonth() - 1))
            : null;
        const maxDate = minMaxDates.maxDate
            ? new Date(minMaxDates.maxDate.setMonth(minMaxDates.maxDate.getMonth() + 1))
            : null;
        return { minDate, maxDate };
    };

    const calculateMinMax = useCallback(
        (chartOptions, line) => {
            const { minDate, maxDate } = getMinMaxDates(laboratoryDtoList);

            const xScaleUpdate = {
                type: "time",
                suggestedMin: minDate,
                suggestedMax: maxDate,
            };
            const yList = line.coordinates.map((coordinate) => coordinate.y);
            const yScaleUpdate =
                line?.coordinates != null && _.isArray(line.coordinates) && line.coordinates.length > 0
                    ? {
                          suggestedMax: Math.ceil(Math.max(yList) + Math.max(yList) * 0.1),
                          suggestedMin: Math.max(0, Math.ceil( Math.min(yList) - Math.max(Math.min(yList) * 0.1, 2)))
                      }
                    : {};

            const newOptions = update(chartOptions, {
                scales: {
                    x: { $merge: xScaleUpdate },
                    y: { $merge: yScaleUpdate },
                },
            });

            return newOptions;
        },
        [laboratoryDtoList],
    );

    return (
        <Grid style={{ marginBottom: 40 }}>
            <Grid container justifyContent={"flex-end"} style={{ marginBottom: 20 }}>
                {!readonly && <LaboratoryFormDialog userDefaultUnits={userDefaultUnits} />}
            </Grid>
            {ChartData({ lang }).map((laboratoryChartLine) => (
                <div className={classes.chartContainer} key={laboratoryChartLine?.title + laboratoryChartLine?.index}>
                    {chartDataSet[laboratoryChartLine.index].coordinates.length === 0 ? (
                        <span className={classes.noChartValuesMessage}>
                            {getNoDataForChartLabel(laboratoryChartLine.title)}
                        </span>
                    ) : null}
                    <Grid key={laboratoryChartLine.index} item xs={12} style={{ marginBottom: 40 }}>
                        <div className={classes.chart}>
                            <h4 className={classes.chartTitle}>
                                {laboratoryChartLine.title}

                                <ButtonGroup
                                    style={{ marginLeft: "10px" }}
                                    disableElevation
                                    size="small"
                                    variant="outlined"
                                    color="default"
                                >
                                    {LaboratoryFormSetup.find(
                                        (laboratoryFormDefault) =>
                                            laboratoryFormDefault.name === laboratoryChartLine.name,
                                    ).options.units.map(({ unit, label }) => {
                                        const selectedUnit =
                                            unit ===
                                            (userDefaultUnits?.[laboratoryChartLine.name + "Unit"] ||
                                                substanceBaseUnits[laboratoryChartLine.name]);
                                        return (
                                            <Button
                                                key={unit}
                                                color={selectedUnit ? "primary" : "default"}
                                                style={{
                                                    fontWeight: selectedUnit ? "bold" : "normal",
                                                    pointerEvents: selectedUnit ? "none" : "auto",
                                                }}
                                                onClick={() => {
                                                    if (selectedUnit) return;
                                                    if (!userDefaultUnits) {
                                                        const payload = {
                                                            hemoglobinUnit: substanceBaseUnits[HEMOGLOBIN],
                                                            kreatininUnit: substanceBaseUnits[KREATININ],
                                                            totalcholesterolUnit: substanceBaseUnits[TOTAL_CHOLESTEROL],
                                                            ldlcholesterolUnit: substanceBaseUnits[LDL_CHOLESTEROL],
                                                            hdlcholesterolUnit: substanceBaseUnits[HDL_CHOLESTEROL],
                                                            crpUnit: substanceBaseUnits[CRP],
                                                            leukozytenUnit: substanceBaseUnits[LEUKOZYTEN],
                                                            mcvUnit: substanceBaseUnits[MCV],
                                                            rdwUnit: substanceBaseUnits[RDW],
                                                            thrombozytenUnit: substanceBaseUnits[THROMBOZYTEN],
                                                            gfrUnit: substanceBaseUnits[GFR],
                                                            ...{ [laboratoryChartLine.name + "Unit"]: unit },
                                                        };
                                                        setMyDefaultLabUnitsMutation({ payload });
                                                    } else {
                                                        setMyDefaultLabUnitsMutation({
                                                            payload: {
                                                                ...userDefaultUnits,
                                                                [laboratoryChartLine.name + "Unit"]: unit,
                                                            },
                                                        });
                                                    }
                                                }}
                                            >
                                                {label}
                                            </Button>
                                        );
                                    })}
                                </ButtonGroup>
                            </h4>
                            <Line
                                id={`chart-${laboratoryChartLine.index}`}
                                key={`chart-${laboratoryChartLine.index}`}
                                //Note LM: Third party was developed by retards who named coordinates -> "data",
                                // inside the object representing the chart Line which they also call... "data".
                                // But hey they use Typescript! and guess what they have called the class? That's right TData!!!
                                // Shits unreal out there.
                                data={{ datasets: [{...chartDataSet[laboratoryChartLine.index], data: chartDataSet[laboratoryChartLine.index].coordinates}] }}
                                options={{ ...calculateMinMax(ChartOptions, chartDataSet[laboratoryChartLine.index]) }}
                            />
                        </div>
                    </Grid>
                </div>
            ))}
        </Grid>
    );
};

export default LaboratoryDashboard;
