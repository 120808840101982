import Flex from "components/grid/Flex";
import { useTranslation } from "react-i18next";
import { useCreateHistoryLogMutation, useGetHistoryLogsQuery } from "../PatientApi";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HmoAvatar from "components/avatar/HmoAvatar";
import HmoTextField from "components/hmo-textfield/HmoTextField";
import HmoFilledButton from "components/button/HmoFilledButton";
import { Checkbox, MenuItem, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useEnqueueError, useEnqueueSuccess } from "components/alert/SnackbarHooks";
import DefaultTextField from "../../../components/hmo-textfield/DefaultTextField";
import LockIcon from "@material-ui/icons/Lock";

export const ALL = "ALL";
export const PRIVATE = "PRIVATE";
export const MATCHING_CARE_PROVIDERS = "MATCHING_CARE_PROVIDERS";

const HistoryLogTypeSelector = ({ setType, type, disabled, user, noLabel }) => {
    const { t: translate } = useTranslation();
    return (
        <DefaultTextField
            standAlone
            disabled={disabled}
            name={"type"}
            onChange={(event) => setType(event.target.value)}
            value={type}
            label={noLabel ? "" : translate("global.type")}
            select
        >
            <MenuItem value={ALL} key={ALL}>
                {translate(`history.ALL`)}
            </MenuItem>
            <MenuItem
                value={MATCHING_CARE_PROVIDERS}
                key={MATCHING_CARE_PROVIDERS}
                disabled={_.isEmpty(user?.careProviderIds)}
            >
                {translate(`history.MATCHING_CARE_PROVIDERS`)}
            </MenuItem>
            <MenuItem value={PRIVATE} key={PRIVATE}>
                {translate(`history.PRIVATE`)}
            </MenuItem>
        </DefaultTextField>
    );
};

const HistoryLog = () => {
    const { t: translate } = useTranslation();
    const { patientId } = useParams();
    const user = useSelector((state) => state.mainReducer.user);
    const { data: historyLogs } = useGetHistoryLogsQuery(patientId);
    const [createHistoryLog] = useCreateHistoryLogMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    //NOTE LM: Expected structure { 2024: [{...log},{...log},{...log}], 2023: [{...log},{...log}]}
    const logsGroupedByYears = _.sortBy(historyLogs, "creationDate").reduce((accumulator, log) => {
        const year = moment(log.creationDate).format("YYYY");
        if (accumulator[year]) {
            accumulator[year] = [...accumulator[year], log];
        } else {
            accumulator = { ...accumulator, [year]: [log] };
        }
        return accumulator;
    }, {});
    const [type, setType] = useState(ALL);
    const [newLog, setNewLog] = useState("");
    const save = () => {
        createHistoryLog({ patientId, text: newLog, type })
            .unwrap()
            .then(() => {
                enqueueSuccess();
            })
            .catch((error) => {
                enqueueError();
            })
            .then(() => {
                setNewLog("");
            });
    };

    return (
        <Flex item container column>
            <Flex
                item
                container
                grow={0}
                style={{
                    height: 59,
                    marginTop: 10,
                    marginBottom: 5,
                    padding: 10,
                    border: "1px solid lightgrey",
                    borderRadius: 6,
                    backgroundColor: "#f8f8fa",
                }}
            >
                <HmoAvatar {...{ user }} style={{ margin: 4 }} />
                <HmoTextField
                    value={newLog}
                    variant={"outlined"}
                    multiline
                    placeholder={translate("global.add-entry")}
                    onChange={(event) => setNewLog(event.target.value)}
                    fullWidth
                    name="comment"
                />
                <HmoFilledButton onClick={save} style={{ marginLeft: 10 }}>
                    {translate("global.save")}
                </HmoFilledButton>
            </Flex>
            <Flex item container alignItems={"center"} grow={0} style={{margin: "5px 0px 5px 0px"}}>
                <HistoryLogTypeSelector {...{ setType, type, user }} />
            </Flex>
            <Flex item grow={0} fullWidth container style={{ borderBottom: "1px solid black" }}>
                <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    {translate("history.interactions-title")}
                </Typography>
            </Flex>
            <Flex item container column style={{ overflow: "auto", height: "79vh" }}>
                {Object.keys(logsGroupedByYears).map((year) => (
                    <Flex item container grow={0} style={{ padding: 10, borderBottom: "1px dashed lightgrey" }}>
                        <Flex item container style={{ maxWidth: 300 }} center>
                            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>{year}</Typography>
                        </Flex>
                        <Flex item container column>
                            {logsGroupedByYears[year].map((log) => (
                                <Flex item container column>
                                    <Flex
                                        item
                                        container
                                        grow={0}
                                        justifyContent={"space-between"}
                                        style={{ padding: "3px 0px 3px 3px" }}
                                    >
                                        <Flex item container grow={0} alignItems={"center"}>
                                            <Typography style={{ marginRight: 7 }}>
                                                {moment(log.creationDate).format("DD. MMM HH:mm")}
                                            </Typography>
                                            <HmoAvatar user={log} small />
                                        </Flex>
                                        <HistoryLogTypeSelector
                                            {...{
                                                setType: () => {},
                                                type: log.type,
                                                disabled: true,
                                                noLabel: true,
                                            }}
                                        />
                                    </Flex>

                                    <Flex
                                        item
                                        grow={0}
                                        container
                                        justifyContent={"space-between"}
                                        style={{
                                            padding: 10,
                                            border: "1px solid lightgrey",
                                            borderRadius: 6,
                                            backgroundColor: "#f8f8fa",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color:
                                                    log.type === PRIVATE
                                                        ? "#cf6866"
                                                        : log.type === MATCHING_CARE_PROVIDERS
                                                          ? "#ff9a03"
                                                          : "#0c0c6d",
                                                whiteSpace: "pre-wrap",
                                            }}
                                        >
                                            {log.text}
                                        </Typography>
                                        {[PRIVATE, MATCHING_CARE_PROVIDERS].includes(log.type) && (
                                            <LockIcon
                                                style={{
                                                    fill: log.type === PRIVATE ? "#cf6866" : "#ff9a03",
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

export default HistoryLog;
