import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Dialog, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    useSaveLaboratoryMutation,
    useUpdateLaboratoryValueMutation,
    useDeleteLaboratoryMutation,
    useFetchLaboratoryByPatientQuery
} from "../LaboratoryApi";
import CommonDialog from "components/common-dialog/CommonDialog";
import * as Yup from "yup";
import {makeStyles} from "@material-ui/core/styles";
import {Field} from "formik";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import Divider from "@mui/material/Divider";
import {LaboratoryFormSetup} from "../config";
import {CCUSThresholds, HEMOGLOBIN, LaboratoryKeys, LEUKOZYTEN, MCV, RDW, THROMBOZYTEN} from 'domain/Laboratory.model';
import {validationError} from "scenes/patient/anamnesis/component/config/ValidationSchema";
import _ from 'lodash';
import CustomDatePicker from "scenes/patient/anamnesis/component/input/CustomDatePicker";
import {shouldDeleteWholeLaboratoryEntry} from '../utils/shouldDeleteWholeLaboratoryEntry';
import Flex from "components/grid/Flex";
import {
    accumulateLaboratoryValuesWithUnit, convertHemoglobinUnits,
    getLaboratoryValuesWithConvertedUnits
} from "scenes/patient/my-risk/calculators/mapperUtils";
import {useFetchPatientByIdQuery} from "scenes/patient/PatientApi";
import {useAddTagMutation} from "scenes/patient/TagApi";
import {FULL_PATIENT} from "domain/EntityAuthorizationType.model";
import moment from "moment";
import { useIsManager } from '../../../../utils';
import {useSelector} from "react-redux";

const itemStyle = {
    maxWidth: '4px',
    minHeight: '25px',
    float: 'left',
    width: '4px',
    marginRight: '4px',
    marginLeft: '15px',
    borderRadius: '10px'
};

const useStyles = makeStyles((_theme) => ({
    root: {
        '& .MuiGrid-item': {
            color: '#030303'
        },
    },
    laboratoryValuesContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: _theme.spacing(1),
        marginTop: _theme.spacing(1),
        marginBottom: _theme.spacing(1),
        maxHeight: 'calc(100vh - 280px)'
    },
    primaryButton: {
        background: '#245B67',
        '&:hover': {
            background: '#245B67',
        }
    },
    subtitle: {
        color: '#030303',
        marginLeft: '15px'
    },
    itemTitle: {
        color: '#030303',
    },
    'item-hemoglobin': {
        backgroundColor: '#F0AF07',
        ...itemStyle
    },
    'item-kreatinin': {
        backgroundColor: '#9B51C6',
        ...itemStyle
    },
    'item-totalcholesterol': {
        backgroundColor: '#245B67',
        ...itemStyle
    },
    'item-ldlcholesterol': {
        backgroundColor: '#CF3A59',
        ...itemStyle
    },
    'item-hdlcholesterol': {
        backgroundColor: '#859EC2',
        ...itemStyle
    },
    'item-crp': {
        backgroundColor: '#777777',
        ...itemStyle
    },
    'item-leukozyten': {
        backgroundColor: '#3980a0',
        ...itemStyle
    },
    'item-mcv': {
        backgroundColor: '#5f7204',
        ...itemStyle
    },
    'item-rdw': {
        backgroundColor: '#2900db',
        ...itemStyle
    },
    'item-thrombozyten': {
        backgroundColor: '#a8206b',
        ...itemStyle
    },
    'item-gfr': {
        backgroundColor: '#7b883b',
        ...itemStyle
    }

}));

//TODO LM: Blast from the past... This supposed to catch strings like '123', '123,456', '123.45', '1,234,567.89'
const DecimalPlacesRegex = /^\d+(?:,\d+)*(?:\.\d+)?$/;

const validationSchema = Yup.object().shape({
    recordDate: Yup.string()
            .when(["recordDate"], (val, _schema) => {
                if (val != null && val.length > 0 && _.isString(val)) {
                    return Yup.string().notRequired();
                }
                return Yup.string().required("Required");
            })
}, [["recordDate", "recordDate"]]);

const CCUSDialog = props =>{
    const {onClose, CCUSData, patientData} = props;
    const {t} = useTranslation();
    const [addTag] = useAddTagMutation();
    const tagUserDetails = () => {
        addTag({
            entityId: patientData.id,
            entityType: FULL_PATIENT,
            tag: 'CCUS?'
        }).unwrap().finally(()=>onClose());
    };
    return <Dialog open={true} maxWidth={'md'} onClose={onClose}>
        <Flex item container column padding={15} style={{maxWidth: 650}}>
            <Typography variant={'h3'} style={{color: 'orange'}}>{t('laboratory.ccus-title')}</Typography>
            <Typography style={{marginTop:10}}>{t('laboratory.ccus-text1')}</Typography>
            <Flex container column padding={25}>
                {
                    CCUSData.map(({key, newValue, oldValue, unit}, index) => {
                        return <Grid container key={index} spacing={1}>
                            <Grid item xs={3}>
                                <Typography style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
                                    {t(`laboratory.timeline.${key}`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>{newValue}&nbsp;{unit?.replace('percent', '%')?.replace("per", "/")}</Typography>
                            </Grid>
                            {
                                !!oldValue &&
                                    <Grid item xs={3}>
                                        <Typography>({t('laboratory.last-time')}&nbsp;{oldValue})</Typography>
                                    </Grid>
                            }
                        </Grid>
                    })
                }
            </Flex>
            <Typography>{t('laboratory.ccus-text2')}</Typography>
            <Flex container style={{marginTop: 10}} justifyContent={'space-between'}>
                <Button fullWidth variant={'outlined'} style={{marginRight: 10}} onClick={onClose}>
                    {t('laboratory.ignore')}
                </Button>
                <Button fullWidth variant={'contained'} color={'secondary'} onClick={tagUserDetails}>
                    {t('laboratory.remember-patient')}
                </Button>
            </Flex>
        </Flex>
    </Dialog>;
};

const getConvertedLaboratoryValues = (laboratoryData) => {
    return getLaboratoryValuesWithConvertedUnits(
            accumulateLaboratoryValuesWithUnit(laboratoryData), {
                [HEMOGLOBIN]: (value, unit) => convertHemoglobinUnits(value, unit)
            });
}

const LaboratoryFormDialog = (props) => {
    const userDefaultUnits = props.userDefaultUnits;
    const managerRoleSelected = useIsManager();
    const {existingLaboratoryDto = {}, forceOpen, reset, ...otherProps} = props;
    const classes = useStyles();
    const {patientId} = useParams();
    const user = useSelector((state) => state.mainReducer.user);
    const careProviderId = user?.careProviderIds?.[0];
    const {
        data: originalLaboratoryData,
    } = useFetchLaboratoryByPatientQuery({patientId}, {skip: !patientId});
    const {data: patientData} = useFetchPatientByIdQuery(patientId);
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`laboratory.timeline.${name}`), [t]);
    const [open, setOpen] = useState(false);
    const [CCUSData, setCCUSData] = useState([]);
    const [addTag] = useAddTagMutation();
    const toggleOpen = () => {
        if (open && reset) reset();
        setOpen(!open)
    };

    const checkCcus = (newLaboratoryData) => {
        const newLaboratoryValues = getConvertedLaboratoryValues([newLaboratoryData]);
        const oldLaboratoryValues = getConvertedLaboratoryValues(originalLaboratoryData);
        const newCcusData = [LEUKOZYTEN, HEMOGLOBIN, MCV, RDW, THROMBOZYTEN].reduce((accumulator, key) => {
            const newValue = parseFloat(newLaboratoryValues[key]?.value);
            const newRecordDate = newValue ? moment(newLaboratoryValues[key]?.recordDate) : undefined;
            const unit = newLaboratoryData[key+'Unit'];
            const oldValue = parseFloat(oldLaboratoryValues[key]?.value);
            const oldRecordDate = oldValue ? moment(oldLaboratoryValues[key]?.recordDate) : undefined;
            const threshold = key === HEMOGLOBIN && patientData?.gender
                    ? CCUSThresholds[`${key}_${patientData?.gender}`]
                    : CCUSThresholds[key];

            if(newValue && newValue < threshold && (!oldValue || oldValue > threshold) && newRecordDate?.isSameOrAfter(oldRecordDate, 'day')) {
                accumulator.push({key, oldValue, newValue, unit});
            }

            return accumulator
        }, []);
        setCCUSData(newCcusData);
        if(!!newCcusData?.length && !managerRoleSelected){
            addTag({
                entityId: patientData.id,
                entityType: FULL_PATIENT,
                tag: 'CCUS?'
            });
        }

    }

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
        }
    }, [forceOpen]);

    const [dialogProperties] = useState({
        fullWidth: true,
        maxWidth: "sm"
    });

    const [saveLaboratory] = useSaveLaboratoryMutation();
    const [deleteLaboratoryMutation] = useDeleteLaboratoryMutation();
    const [updateLaboratory] = useUpdateLaboratoryValueMutation();

    const handleSave = (laboratoryData) => {
        if (laboratoryData.ids && laboratoryData.ids.length > 0) {
            //TODO LM: I guess this wants to be the update route, which start by deleting the the records??? why?
            if (shouldDeleteWholeLaboratoryEntry(laboratoryData)) {
                deleteLaboratoryMutation({ids: laboratoryData.ids }).unwrap().finally(() => toggleOpen())
            } else {
                //TODO LM: I suppose this monstrosity is trying to convert german decimal indicators to human ones
                // in the most convoluted way imaginable. Check it.
                // It also conveniently adds O(n*3) number of loops when it could do it on one go.
                const mappedPayload = Object.fromEntries(Object.entries(laboratoryData).map(([key, value]) => {
                    if (!LaboratoryKeys.includes(key)) return [key, value];
                    if (typeof value === 'string') {
                        return [key, parseFloat(DecimalPlacesRegex.test(value) ? value.replace(',', '.') : value || 0)]
                    }
                    return [key, value];
                }));
                updateLaboratory({
                    payload: mappedPayload
                }).unwrap()
                        .then(()=>checkCcus(laboratoryData))
                        .finally(() => toggleOpen())
            }

        } else {
            saveLaboratory({
                ...Object.fromEntries(Object.entries(laboratoryData).map(([key, value]) => {
                    return [key, DecimalPlacesRegex.test(value) ? parseFloat(value.replace(',', '.')) : value];
                })),
                patientId,
                careProviderId
            }).unwrap()
                    .then(()=>checkCcus(laboratoryData))
                    .finally(() => toggleOpen());
        }
    };

    const emptyLaboratoryFormData = useMemo(() =>
            LaboratoryFormSetup.reduce((accumulator, {name, options}) => {
                accumulator[name] = existingLaboratoryDto[name] ? existingLaboratoryDto[name] : '';
                accumulator[name + 'Unit'] = userDefaultUnits?.[name.toLowerCase() + 'Unit'] ? userDefaultUnits[name.toLowerCase() + 'Unit'] : options.defaultUnit;
                return accumulator;
            }, {}), [existingLaboratoryDto, userDefaultUnits]);

    return <>
        <Button
                className={classes.primaryButton}
                onClick={toggleOpen}
                variant="contained"
                color="primary"
        >
            {lang("addButton")}
        </Button>
        {
                !!CCUSData?.length && managerRoleSelected &&
                <CCUSDialog onClose={()=>setCCUSData([])} CCUSData={CCUSData} patientData={patientData}/>
        }
        <CommonDialog dialogTitle={existingLaboratoryDto.ids != null && existingLaboratoryDto.ids.length>0 ? lang('editTitle') : lang('createTitle')}
                      validationSchema={validationSchema}
                      dialogProps={dialogProperties}
                      handleClose={toggleOpen}
                      initialValues={
                          {
                              ...emptyLaboratoryFormData,
                              ...existingLaboratoryDto,
                          }
                      }
                      maxWidth={'sm'}
                      {...{open}}
                      {...otherProps}
                      {...{handleSave}}
        >

            {(form) => (
                    <div className={classes.root}>
                        <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={8}>
                                <div className={classes.subtitle}>{lang('date')}</div>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                        name="recordDate"
                                        type="date"
                                        fullWidth
                                        as={CustomDatePicker}
                                        onChange={(e) => form.setFieldValue('recordDate', e.target.value)}
                                        {...validationError(form, 'recordDate')}
                                />
                            </Grid>

                        </Grid>

                        <Divider flexItem light orientation="horizontal" variant="fullWidth"
                                 style={{width: '100%'}}/>

                        <div className={classes.laboratoryValuesContainer}>
                            {LaboratoryFormSetup.map((laboratoryFormDefault) => (

                                    <Grid container key={`form-item-${laboratoryFormDefault.name}`} spacing={1} justifyContent={'center'}
                                          alignItems={'center'}>
                                        <Grid item xs={6}>
                                            <div className={classes[`item-${laboratoryFormDefault.name}`]}/>
                                            <div className={classes.itemTitle}>{lang(laboratoryFormDefault.name)}</div>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <EpaTextField
                                                    name={laboratoryFormDefault.name}
                                                    onChange={form.handleChange}
                                                    placeholder={laboratoryFormDefault?.options?.mask ?? '0000,00'}
                                                    value={form.values[laboratoryFormDefault.name]}
                                                    {...validationError(form, laboratoryFormDefault.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{marginLeft: 10}}>
                                                <Select
                                                        name={`${laboratoryFormDefault.name}Unit`}
                                                        fullWidth
                                                        displayEmpty
                                                        onChange={event => form.setFieldValue(`${laboratoryFormDefault.name}Unit`, event?.target?.value ?? 'gperdl')}
                                                        value={form?.values[`${laboratoryFormDefault.name}Unit`] ?? laboratoryFormDefault?.options?.defaultUnit ?? ''}
                                                >
                                                    {laboratoryFormDefault.options && laboratoryFormDefault.options.units.map(unit => (
                                                            <MenuItem key={`${laboratoryFormDefault.name}-unit-${unit.value}`}
                                                                      value={unit.value}>
                                                                {unit?.label}
                                                            </MenuItem>))}
                                                </Select>
                                            </div>
                                        </Grid>
                                    </Grid>
                            ))}
                        </div>
                    </div>
            )}
        </CommonDialog>
    </>;
};

export default LaboratoryFormDialog;
