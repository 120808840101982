import Flex from "components/grid/Flex";
import { Button, Checkbox, Typography } from "@material-ui/core";
import utils from "../../utils/Utils";
import { Cell } from "../managed-care/PatientRow";
import { useSelector } from "react-redux";
import Tag from "components/tags/Tag";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSendRequestToPatientMutation } from "./CohortApi";
import { useEnqueueError, useEnqueueSuccess } from "../../components/alert/SnackbarHooks";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";
import { useState } from "react";

const CohortPatientRow = (props) => {
    const { checkedPatientIds, patient, style, selectPatient, checkPatient, handleOnRightClick } = props;
    const { t } = useTranslation();
    const checked = checkedPatientIds.includes(patient.patientId);
    const onCheck = (event) => {
        event.stopPropagation();
        event.preventDefault();
        checkPatient(patient.patientId);
    };
    const isAnon = patient?.familyName?.includes("Anonymous-");
    const user = useSelector((state) => state.mainReducer.user);
    const [sendRequestToPatient] = useSendRequestToPatientMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const [isSendRequestOpen, setIsSendRequestOpen] = useState(false);
    const [requestJustSent, setRequestJustSent] = useState(false);
    const toggleSendRequestDialog = () => {
        setIsSendRequestOpen(!isSendRequestOpen);
    };
    const onSendRequestToPatientClick = () => {
        sendRequestToPatient({ patientId: patient.patientId, careProviderId: user.careProviderIds[0] })
            .then(() => enqueueSuccess())
            .catch(() => enqueueError())
            .finally(() => {
                setIsSendRequestOpen(false);
                setRequestJustSent(true);
            });
    };

    return (
        <div style={style}>
            <Flex
                item
                grow={0}
                container
                style={{
                    border: "1px solid #E2E1E4",
                    borderTop: "unset",
                    height: 40,
                    backgroundColor: "white",
                }}
                onClick={isAnon ? () => {} : selectPatient(patient.patientId)}
                onContextMenu={handleOnRightClick}
            >
                <Flex item={"1 0 5%"} container style={{ width: "5%", alignItems: "center" }}>
                    <Checkbox
                        {...{
                            size: "small",
                            checked,
                            onClick: onCheck,
                        }}
                    ></Checkbox>
                    <Typography
                        style={{
                            color: !patient?.userId ? "darkgray" : "#030303",
                            opacity: 1,
                            bold: "unset",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontStyle: !patient?.userId ? "italic" : "initial",
                        }}
                    >
                        {utils.formattedUserNameWithComma(patient)}&nbsp;
                    </Typography>
                </Flex>
                <Cell left center={false} {...{ text: patient?.clincNames || "HMO" }} />
                <Cell left center={false}>
                    {(patient?.programs?.split(",") || []).map((tag) => {
                        return <Tag {...{ tag }} />;
                    })}
                </Cell>

                <Cell left center={false} {...{ text: patient?.age }} />
                <Cell left center={false}>
                    {isAnon && (
                            <Button size={"small"} color={"primary"} onClick={toggleSendRequestDialog}>
                                {requestJustSent
                                        ? t("cohort.request-just-sent")
                                        : patient?.lastRequestSentAt
                                                ? t("cohort.request-already-sent") + ': '+ moment(patient?.lastRequestSentAt).format("DD.MM.YYYY")
                                                : t("cohort.send-request-to-patient")}
                            </Button>
                    )}
                    <ConfirmationDialog
                        {...{
                            dialogOpen: isSendRequestOpen,
                            onDialogClose: toggleSendRequestDialog,
                            onConfirm: onSendRequestToPatientClick,
                            confirmationTextKey: "cohort.send-request-to-patient-confirmation",
                            title: t("cohort.send-request-to-patient-title"),
                        }}
                    />
                </Cell>
            </Flex>
        </div>
    );
};

export default CohortPatientRow;
